import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import NavBar from './component/NavBar';
import Home from './page/Home';
import Privacy from './page/Privacy';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <NavBar />
        </header>
        <main>
          <Switch>
            <Route
              exact path="/"
              component={Home} />
            <Route
              exact
              path="/privacy"
              component={Privacy} />

          </Switch>
        </main>
      </div>
    );
  }
} export default App;
