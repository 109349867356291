import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import logo from '../logo.png';

class NavBar extends Component {
    render() {
        return (
            <div className="NavBar">
                <img src={logo} className="App-logo" alt="logo" />
                <div className="links-container">
                    <div className="link-container">
                        <Link to="/" className="link">Home</Link>
                    </div>
                    <div className="link-container">
                        <Link to="/privacy" className="link">Privacy policy</Link>
                    </div>
                </div>
            </div>
        );
    }
} export default NavBar;