import React from 'react';
import './Privacy.css';

function Privacy() {
    return (
        <div className="Privacy">
            <h2 className="center">PRIVACY POLICY</h2>
            <p></p>
            <p>This privacy policy governs your use of the software application Pasturometro (“Application”) for mobile devices that was
            created by&nbsp;Azous&nbsp;The Application is&nbsp;Basic description of the app (features,
            functionality and content)&nbsp;
                    </p>
            <h4>&nbsp;</h4>
            <h4>What information does the Application obtain and how is it used?</h4>
            <p></p>

            <p><strong>User Provided Information</strong>&nbsp;</p>
            <p>The Application obtains the information you provide when you download and register the Application.&nbsp;Registration
            with us is optional. However, please keep in mind that you may not be able to use some of the features offered by
            the Application unless you register with us.</p>
            <p></p>
            <p>&nbsp;</p>
            <p>When you register with us and use the Application, you generally provide&nbsp;(a) your name, email address, age, user
            name, password and other registration information; (b) transaction-related information, such as when you make
            purchases, respond to any offers, or download or use applications from us; (c) information you provide us when you
            contact us for help; (d) credit card information for purchase and use of the Application, and; (e) information you
            enter into our system when using the Application, such as contact information and project management information.
            </p>
            <p></p>
            <p>We may also use the information you provided us to contact your from time to time to provide you with important
            information, required notices and marketing promotions.</p>

            <p><strong>Automatically Collected Information</strong>&nbsp;</p>
            <p>&nbsp;</p>
            <p>In addition, the Application may collect certain information automatically, including, but not limited to, the type
            of mobile device you use, your mobile devices unique device ID, the IP address of your mobile device, your mobile
            operating system, the type of mobile Internet browsers you use, and information about the way you use the
            Application.&nbsp;</p>
            <p></p>
            <h4>&nbsp;</h4>
            <h4>Does the Application collect precise real time location information of the device?</h4>
            <p></p>
            <p>When you start the mobile application, we use GPS technology (or other similar technology) to determine your
            current location in order to store where you were located when a measurement was done and display a location map
            with georeferences measures. We will not share your current or past location with other users or partners.</p>
            <p></p>
            <p className="hidden">
                If you do not want us to use your location for the purposes set forth above, you should turn off the location
                services for the mobile application located in your account settings or in your mobile phone settings and/or within
                the mobile application.&nbsp;
            </p>
            <p>&nbsp;</p>
            <h4>Do third parties see and/or have access to information obtained by the Application?</h4>
            <p></p>
            <p>No. We will not share your information with third parties<span className="hidden">only in the ways that are described in this privacy statement</span>.
        </p>
            <p>We may disclose User Provided and Automatically Collected Information:</p>
            <ul>
                <li>
                    <p>as required by law, such as to comply with a subpoena, or similar legal process;</p>
                </li>
                <li>
                    <p>when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the
                    safety of others, investigate fraud, or respond to a&nbsp;government request;</p>
                </li>
                <li>
                    <p>with our trusted services providers who work on our behalf, do not have an&nbsp;independent use of the
                    information we disclose to them, and have agreed to adhere&nbsp;to the rules set forth in this privacy
                    statement.</p>
                </li>
                <li>
                    <p>if&nbsp;Azous&nbsp;is involved in a merger, acquisition, or sale of all or a&nbsp;portion of
                    its assets, you will be notified via email and/or a prominent notice on our Web site of any change in
                    ownership or uses of this information, as well as any choices you may have regarding this information.</p>
                </li>
            </ul>
            <p></p>
            <p>&nbsp;</p>
            <h4>What are my opt-out rights?</h4>
            <p></p>
            <p>You can stop all collection of information by the Application easily by uninstalling the Application. You may use the
            standard uninstall processes as may be available as part of your mobile device or via the mobile application
            marketplace or network. You can also request to opt-out via email, at pasturometro@azous.com.ar.</p>
            <p>&nbsp;</p>
            <h4><strong>Data Retention Policy, Managing Your Information</strong></h4>
            <p>We will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. We
            will retain Automatically Collected information for up to 24 months&nbsp;and thereafter may store it in aggregate.
            If you’d like us to delete User Provided Data that you have provided via the Application, please contact us
            at&nbsp;pasturometro@azous.com.ar&nbsp;and we will respond in a reasonable time. Please note that some or all of
            the User Provided Data may be required in order for the Application to function properly.</p>
            <p>&nbsp;</p>
            <h4><strong>Children</strong></h4>
            <p></p>
            <p>We do not use the Application to knowingly solicit data from or market to children under the age of 13. If a parent
            or guardian becomes aware that his or her child has provided us with information without their consent, he or she
            should contact us at&nbsp;pasturometro@azous.com.ar. We will delete such information from our files within a
            reasonable time.</p>
            <h4>&nbsp;</h4>
            <h4><strong>Security</strong></h4>
            <p></p>
            <p>We are concerned about safeguarding the confidentiality of your information. We provide physical, electronic, and
            procedural safeguards to protect information we process and maintain. For example, we limit access to this
            information to authorized employees and contractors who need to know that information in order to operate, develop
            or improve our Application. Please be aware that, although we endeavor provide reasonable security for information
            we process and maintain, no security system can prevent all potential security breaches.</p>
            <p>&nbsp;</p>
            <h4><strong>Changes</strong></h4>
            <p>This Privacy Policy may be updated from time to time for any reason. We will notify you of any changes to our Privacy
            Policy by posting the new Privacy Policy&nbsp;here&nbsp;and&nbsp;informing you via email or text message. You are
            advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all
            changes. You can check the history of this policy by clicking here.</p>
            <p>&nbsp;</p>
            <h4><strong>Your Consent</strong></h4>
            <p>By using the Application, you are consenting to our processing of your information as set forth in this Privacy
            Policy now and as amended by us. &quot;Processing,” means using cookies on a computer/hand held device or using or
            touching information in any way, including, but not limited to, collecting, storing, deleting, using, combining and
            disclosing information, all of which activities will take place in the United States. If you reside outside the
            United States&nbsp;your information will be transferred, processed and stored there under United States&nbsp;privacy
            standards.&nbsp;</p>
            <p>&nbsp;</p>
            <h4>Contact us</h4>
            <p>If you have any questions regarding privacy while using the Application, or have questions about our practices,
            please contact us via email at&nbsp;pasturometro@azous.com.ar.</p>
        </div>
    );
}

export default Privacy;