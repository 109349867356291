import React from 'react';

function Home() {
    return (
        <div>
            <p>
                Politica de Datos
            </p>
            <p>
                Los datos de ubicación solo seran almacenados en la base de datos local del dispositivo.
                Ninguna información obtenida por la aplicación será compartida sin acción expresa del usuario.
                El usuario puede enviar los datos si lo desea utilizando la función de exportar.
                Esta función genera un archivo para ser compartido via las redes disponibles.
            </p>
        </div>
    );
}

export default Home;